<template class="home">
    <section>
        <div v-if="isLoading">
            <vue-lottie :options="lottieOptions" :width="180" :height="100" />
        </div>

        <div v-else>
            <div class="categories-container pr-4">
                <b-button rounded class="mb-5 category--tab" :class="{ 'is-primary': activeTab === index }"
                    v-for="(categoryKey, index) in categoriesKeys" :key="index" @click="onCategoryClick(index)">
                    <span>{{ categoryKey }}</span></b-button>
            </div>

            <TicketsAndActivitiesDesktop class="mb-6" v-if="isDesktopView" :is-experiences-ids="false"
                :Experiences="activities[categoriesKeys[activeTab]]" :Tickets="orderedTickets[categoriesKeys[activeTab]]" />
            <TicketsAndActivitiesContainer ref="activities" class="mb-6" v-else is-full-width :is-experiences-ids="false"
                :Experiences="activities[categoriesKeys[activeTab]]" :Tickets="orderedTickets[categoriesKeys[activeTab]]" />
        </div>
        <CustumTourContainer class="mt-6 mb-6"/>

        <p class="is-size-4 has-text-weight-bold mt-6 mb-5">Our insider tips</p>
        <BlogArticleCard class="blog-articles" :articles="articles" />

        <Testimonials class="mt-3 mb-3"  />

        <p class="is-size-4 has-text-weight-bold  mb-5 mt-2">Highlighted Attractions</p>


        <b-collapse animation="slide" v-for="(attraction, index) in Attractions" :key="index" open @open="isOpen = index">
            <template #trigger="props">
                <div class="attraction">
                    <img class="attraction--img" :src="attraction.img" />
                    <p class="attraction--tile">
                        <span class="highlight">{{ attraction.name }}</span>
                    </p>
                </div>

                <div class="attraction-details--container">
                    <p class="attraction--details">{{ attraction.description }}</p>
                    <div class="collapse--btn is-flex is-align-items-center" v-if="attraction.experiences">Ways to
                        experience ({{ attraction.experiences.length + attraction.tickets.length }})
                        <a class="card-header-icon" v-show="props.open">
                            <b-icon icon="chevron-up"></b-icon>
                        </a>
                        <a class="card-header-icon" v-show="!props.open">
                            <b-icon icon="chevron-down"></b-icon>
                        </a>
                    </div>

                </div>
            </template>
            <TicketsAndActivitiesContainer :is-full-width="false" :Experiences="attraction.experiences" :Tickets="attraction.tickets"/>
        </b-collapse>
    </section>
</template>

<script>
import Testimonials from '../components/Testimonials.vue';
import CustumTourContainer from '../components/CustumTourContainer.vue';
import TicketsAndActivitiesContainer from '../components/TicketsAndActivitiesContainer.vue';
import TicketsAndActivitiesDesktop from '@/components/TicketsAndActivitiesDesktop';
import { ActivityCategories as Categories } from "../enums/ActivityCategories";
import { mapState } from 'vuex';
import {  ActivitiesStates } from '../store/Storetypes';
import { HelpersMixin } from '../mixins';
import BlogArticleCard from '../components/elements/BlogArticleCard.vue';
import VueLottie from 'vue-lottie';
export default {
    props: ['orderedActivities', 'isLoading', 'tickets'],
    mixins: [HelpersMixin],
    components: {
        VueLottie,
        Testimonials,
        CustumTourContainer,
        BlogArticleCard,
        TicketsAndActivitiesContainer,
        TicketsAndActivitiesDesktop
    },
    data() {
        return {
            isOpen: -1,
            activeTab: 0,
            categoryKeys: [],
            orderedTickets: {},
            Categories,
            lottieOptions: {
                animationData: require('@/assets/animations/loading-an.json'),
                loop: true,
                autoplay: true,
            },
            Attractions: [
                {
                    name: "Quinta da Regaleira",
                    description: "A mesmerizing estate in Lisbon, Portugal, known for its stunning architecture and mystical gardens.",
                    experiences: ['D1YH6p0pVThgornEsYhe'],
                    tickets: ['2VJ3888cMamYBoscKaCN'],
                    img: "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/attraction%2Fimage%20203.png?alt=media&token=e2602e89-900b-446c-b318-6acc0407843b"
                },
                {
                    name: "Pena Palace",
                    experiences: ['D1YH6p0pVThgornEsYhe'],
                    tickets: ['8VYdIS0QYTK5e8CkBJIy', 'WvuU8qifxuue5GWWZvKJ'],
                    description: "A magnificent castle located in Sintra, Portugal, renowned for its vibrant colors and romantic architecture.",
                    img: "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/attraction%2Fimage%20204.png?alt=media&token=1a02460d-4bd6-4a52-a5d9-bf08fb677431"
                },
                {
                    name: "Castle of the Moors",
                   // experiences: ['D1YH6p0pVThgornEsYhe'],
                    experiences: [],
                    tickets: ['2VJ3888cMamYBoscKaCN'],
                    description: "The Castle of the Moors is an ancient fortress in Sintra, Portugal, offering panoramic views of the surrounding landscape and a glimpse into the region's rich history.",
                    img: "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/attraction%2Fimage%20205.png?alt=media&token=b7b70e2a-102b-496a-851a-538b791da036"
                },
                {
                    name: "Monserrate Palace",
                    //experiences: ['D1YH6p0pVThgornEsYhe'],
                   tickets: [],
                    description: "Monserrate Palace is a picturesque palace located in Sintra, Portugal, celebrated for its exquisite blend of Gothic, Indian, and Moorish architectural styles.",
                    img: "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/attraction%2Fimage%20206.png?alt=media&token=dbb8d769-f3cc-4922-a211-610394b603ae"
                },
                {
                    name: "Sintra Palace",
                    experiences: ['D1YH6p0pVThgornEsYhe'],
                    tickets: [],
                    description: "Sintra Palace, located in Sintra, Portugal, is a magnificent castle known for its vibrant colors and romantic architecture.",
                    img: "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/attraction%2Fimage%20207.png?alt=media&token=e5a1e19e-9ca1-4331-8566-e7575ff06c22"
                }
            ],
            articles: [
                {
                    name: "Best things to see and do in Sintra in a day.",
                    img: 'https://images.pexels.com/photos/8163130/pexels-photo-8163130.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
                    href: 'guide-sintra',
                    badge: {
                        text: 'Blog article',
                        icon: require("../assets/newspaper-solid.svg")
                    },
                    date: "March 10, 2024"
                }
            ]
        }
    },
    methods: {
        onCategoryClick(index){
            this.activeTab = index
            this.$refs.activities.reset()
        },
        prepareTickets(ticketIds) {
            if (!this.tickets) return
            return this.tickets.filter((ticket) => ticketIds.includes(ticket.id))
        },
        getExperienceById(experiences, type = "tours") {
            return this[type].filter((element) => experiences.includes(element.id))
        },
        getExperiences(activitiesIds, ticketsIds) {
            const activities = this.getExperienceById(activitiesIds)
            const tickets = this.getExperienceById(ticketsIds, 'tickets')
            return [...activities, ...tickets]
        },
        setOrderedTickets(items) {
            this.orderedTickets = items
        },
        getAllExperiences() {
            const experiences = []
            this.tours.map((tour) => experiences.push(tour))
            this.tickets.map((tour) => experiences.push(tour))

            return experiences
        }
    },
    computed: {
        ...mapState({
            tours: state => state.activities[ActivitiesStates.ACTIVITIES]
        }),
        activities() {
            return this.orderedActivities

        },
        ticketsCategories() {
            let ticketsCategories = {}
            this.tickets.map((tour) => {
                if (!tour?.categories) return
                Categories.forEach((category) => {
                    if (category.index === 0) return ticketsCategories['All'] = this.getAllExperiences()
                    if (tour.categories.includes(category.index))
                        ticketsCategories[category.name] = !ticketsCategories[category.name]?.length ?
                            [tour] :
                            [
                                ...ticketsCategories[category.name],
                                tour]
                })
            });
            this.setOrderedTickets(ticketsCategories)
            return ticketsCategories
        },
        categoriesKeys() {
            const ticketsCategories = Object.keys(this.ticketsCategories)
            const activityCategories = Object.keys(this.activities)
           
            const categories = [...activityCategories, ...ticketsCategories]
             const uniqueCategories = [...new Set(categories)]
            return uniqueCategories.sort((a, b) => {
                // Convert names to lowercase for case-insensitive sorting
                let nameA = a.toLowerCase();
                let nameB = b.toLowerCase();

                // Compare the names
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0; // Names are equal
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
.blog-articles{
    margin: -20px;
    padding: 20px;
    margin-bottom: 2.25rem;
}

.categories-container {
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-right: -20px;
    margin-left: -20px;
    padding-left: 20px;
    
    &::-webkit-scrollbar {
      display: none;
    }
    .button:not(.is-primary) {
        border: 2px solid rgba(29, 45, 83, 0.9098039216)
    }
    .category--tab{
        height: 2.5rem;
    }
  }

.attraction {
    position: relative;
    height: 264px;
    

    .attraction--tile {
        color: #0e3d4d;
        position: absolute;
        left: 12px;
        bottom: 12px;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .highlight {
        background-color: #73FBD3;
        padding: 2px 11px;
        border-radius: 4px;
        -webkit-box-decoration-break: clone;
    }

    .attraction--img {
        border-radius: 12px 12px 0 0;
        height: -webkit-fill-available;
        width: -webkit-fill-available;
    }

}

.attraction-details--container {
    border-radius: 0 0 8px 8px;
    margin-bottom: 1rem;
    .attraction--details{
        padding: 12px;
        font-weight: 500;

    }
}

.collapse--btn{
    background-color: rgba(61, 220, 151, 0.1);
    display: flex;
    justify-content: center;
    font-weight: 600;
}

.divider {
    position: absolute;
}

.category--tab {
    height: 3rem;
    font-weight: 700;
    font-size: .9rem;

    &:not(:first-of-type) {
        margin-left: .5rem;
    }
}

.title {
    font-size: 1.35rem;
    font-weight: 500;
}

.subtitle {
    // font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
}

.home .select select,
.taginput .taginput-container.is-focusable,
.input {
    margin-bottom: 0px !important;
}

@media (min-width: 1024px) {
    .columns {
        justify-content: flex-start;
    }
}

.cards-container {
    align-items: flex-end;
    margin: 2rem 0;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(5, minmax(min-content, 7fr));
}

.cards-container--mobile {
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}
</style>
  

