<template>
  <section>
    <div class="columns is-multiline">
      <div
        class="column is-4"
        v-for="(experience, index) in ExperiencesArray"
        :key="index"
        :data-aos="withAnimation ? 'fade-up' : null"
    :data-aos-easing="withAnimation ? 'ease-in-out' : null"
    :data-aos-mirror="withAnimation ? 'true' : null"
    :data-aos-once="withAnimation ? 'false' : null"
      >
        <a
          v-if="!experience?.ticketLink"
          :href="getPageUrl(experience.id, experience.title)"
          @click="goToDetailsPage(experience.id)"
        >
          <div class="image--container">
            <img class="card--image" :src="experience.coverImage" />
            <div
            class="is-flex is-align-items-center activity--rating"
            v-if="experience.stars"
          >
            <b-icon icon="star" class="star-icon"></b-icon
            ><b>{{ experience.stars }}</b>
          </div>
          
          </div>
          <div class="card--content">
          

            <!--  <div class="is-flex is-justify-content-space-between">
              <div
                v-if="experience.stars"
                class="is-flex is-justify-content-flex-end"
              >
                <b-icon
                  class="star-icon"
                  size="is-small"
                  pack="fas"
                  icon="star"
                ></b-icon>
                <span class="rating ml-1">{{ experience.stars }}</span>
              </div>
            </div>  <div  v-if="!experience.price">
              <b-tag class="mr-2" type="is-success">FREE</b-tag>
              <b-tag type="is-danger is-light">Likely to sell-out</b-tag>
            </div>

            <div class="columns is-mobile mt-2" v-else>
              <div class="column pt-2 is-6 pb-3 seperator">
                <b v-if="!experience.isPrivateOnly">Private Tour:</b>
                <div class="price mt-1"><img class="mr-1" src="../assets/ticket-outline.svg" />{{ experience.privatePrice }}€</div>
                  <span class="mb-2 is-size-8">(up to {{ experience.maxNumOfParticipants }} people)</span>
              </div>
              <div class="column pt-2 pb-3 is-6 ml-4">
                <b v-if="!experience.isPrivateOnly">Shared Tour:</b>
                <div class="price mt-1"><img class="mr-1" src="../assets/ticket-outline.svg" />{{ experience.price
                }}€</div>
                <span v-if="!experience.isPrivateOnly" class="mb-2 is-size-8">(per person)</span>
                <span v-else class="mb-2 is-size-8">(up to 8 people)</span>
              </div>
            </div> -->
            <div class="">
              <div class="">
                <p class="title mb-3">{{ experience.title }}</p>

                <div class="is-flex mb-2">
                  <div
                    class="is-flex is-align-items-center activity--duration mr-2"
                    v-if="experience.city"
                  >
                    <img src="../assets/location-icon.svg" class="mr-1 icon" />
                    <span class="duration">{{ experience.city }}</span>
                  </div>
                  <div
                    class="is-flex is-align-items-center activity--duration"
                    v-if="experience.duration"
                  >
                    <img src="../assets/timer-icon.svg" class="mr-1 icon" />
                    <span class="duration">{{
                      translateDuration(experience.duration)
                    }}</span>
                  </div>
                </div>
              </div>
              

              <div class=" column price mt-2" v-if="experience.price">
                <span class="has-text-weight-light mr-1 is-size-7">from</span>
                {{ experience.privatePrice }}€
              </div>
            </div>
          </div>
        </a>

        <div v-else>
          <a :href="experience.ticketLink" target="_blank">
            <div class="img-container">
              <img class="card--image" :src="experience.coverImage" />
              <a :href="experience.ticketLink" target="_blank"
                ><img class="arrow-btn" src="../assets/open-circle.svg"
              /></a>
              <!--           <b-tag type="is-danger" class="featured-tag">Featured</b-tag> -->
            </div>
          </a>

          <div class="card--content">
            <div class="is-flex is-justify-content-space-between mt-1">
              <div
                v-if="experience.stars"
                class="is-flex is-justify-content-flex-end"
              >
                <b-icon
                  class="star-icon"
                  size="is-small"
                  pack="fas"
                  icon="star"
                ></b-icon>
                <span class="rating ml-1">{{ experience.stars }}</span>
              </div>
            </div>

            <p class="title mb-1">{{ experience.title }}</p>

            <div v-if="experience.description">
              <b-collapse
                :open="false"
                position="is-bottom"
                aria-id="contentIdForA11y1"
              >
                <template #trigger="props">
                  <p
                    class="ticket-description mt-3 mb-1"
                    :class="{ 'ticket-description--minimized': !props.open }"
                    v-html="experience.description"
                  ></p>
                  <div
                    class="is-flex is-align"
                    v-if="shouldShowCollapse(experience.description)"
                  >
                    <a class="link" v-show="props.open">
                     Show less <b-icon icon="caret-up"></b-icon> 
                    </a>
                    <a class="link" v-show="!props.open">
                     Show more <b-icon icon="caret-down"></b-icon> 
                    </a>
                  </div>
                </template>
              </b-collapse>
            </div>

            <div class="price mt-2">
              <!-- <img class="mr-1" src="../assets/ticket-outline.svg" /> -->{{
                experience.price
              }}€
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getHoursAndMinutes } from "../helpers";
import { mapState, mapMutations } from "vuex";
import {
  TicketsStates,
  ActivitiesStates,
  ActivitiesMutations,
} from "../store/Storetypes";
import { HelpersMixin } from "../mixins";
export default {
  mixins: [HelpersMixin],
  props: {
    Experiences: {
      type: Array,
      default: () => [],
    },
    Tickets: {
      type: Array,
      default: () => [],
    },
    isExperiencesIds: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      getHoursAndMinutes,
    };
  },
  methods: {
    ...mapMutations({
      setSelectedActivityId: ActivitiesMutations.SET_SELECTED_ACTIVITY_ID,
    }),
    shouldShowCollapse(description) {
      const lines = description.split(/\r\n|\r|\n/).length;
      return lines > 2;
    },
    getExperienceById(experiences, type = "tours") {
      return this[type].filter((element) => experiences.includes(element.id));
    },
    getExperiences(activitiesIds, ticketsIds) {
      const activities = this.getExperienceById(activitiesIds);
      const tickets = this.getExperienceById(ticketsIds, "tickets");
      return [...activities, ...tickets];
    },
    goToDetailsPage(activityId) {
      this.setSelectedActivityId(activityId);
    },
    getPageUrl(activityId, title) {
      return `https://www.guiders.pt/details/${activityId}/${this.slugify(
        title
      )}`;
    },
    translateDuration(duration) {
      return getHoursAndMinutes(duration);
    },
  },
  computed: {
    ...mapState({
      tours: (state) => state.activities[ActivitiesStates.ACTIVITIES],
      tickets: (state) => state.tickets[TicketsStates.TICKETS],
    }),
    ExperiencesArray() {
      if (!this.isExperiencesIds) {
        return [...this.Experiences, ...this.Tickets];
      }
      return this.getExperiences(this.Experiences, this.Tickets);
    },
  },
};
</script>

<style lang="scss" scoped>
.activity--duration {
  color: #0e3d4d;
  border-radius: 8px;
 // background-color: #56a0ba14;
background-color: rgb(255 239 167 / 68%);
  padding: 4px 8px;
  font-weight: 400;
  width: -moz-max-content;
  width: max-content;
  .duration {
    font-size: 0.7rem;
  }
  .icon {
    height: 0.8rem;
    width: 0.8rem;
    object-fit: fill;
  }
}

.columns {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #0e3d4d !important;
}

.column {
  padding: 0.5rem;
}

.seperator {
  position: relative;
}

.seperator:after {
  content: "";
  position: absolute;
  margin-right: 8px;
  border-radius: 19px;
  right: 0px;
  top: 26%;
  height: 66%;
  border-right: 2px solid #0e3d4d12;
}

.activity--rating {
  background-color: #ffffffeb;
    color: #0e3d4d;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    border-radius: 9px;
    padding: 2px 10px 2px 6px;
    font-weight: 500;
    width: -moz-max-content;
    width: max-content;
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 0.8rem;
}

.image--container {
  position: relative;
}
.exclusive--badge {
  position: absolute;
  top: 8px;
  left: 8px;
  color: #0e3d4d;
  font-weight: 600;
  .icon {
    height: 2.2rem;
    width: 2.2rem;
    object-fit: contain;
  }
}

.tag:not(body) {
  margin: 16px 0px;
}
.card--image {
  height: 200px;
  border-radius: 16px;
  width: -webkit-fill-available;
  object-fit: cover;
}

.activity-details {
  font-weight: bold;
  color: #446df6;
}

.has-text-info {
  .icon {
    margin-left: -5px;
  }
}

.card {
  .card-content {
    margin: 1.5rem 0 0.95rem 0;
  }
}

.card--content {
  padding: 0.5rem 0;
}

.ticket-description {
  font-size: 0.9rem;
  white-space: pre-line;
  line-height: 1.5rem;
  font-weight: 300;
  &--minimized {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.img-container {
  position: relative;
}
.activity-details {
  font-weight: bold;
  color: #446df6;
  padding: 6px 12px;
  border-radius: 23px;
  background-color: rgb(213 235 255 / 28%);
}
.card {
  .card-content {
    margin: 1.5rem 0 0.95rem 0;
  }
}
.display-flex-space-btw {
  align-items: center;
}

.card--content {
  padding: 0.6rem;
}

.is-flex {
  align-items: center;
}

.title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  margin-top: 0.25rem;
  margin-bottom: 0.4rem;
  font-size: 1rem;
}

.price {
  font-size: 1.05rem;
  border-radius: 8px;
  padding: 2px 0;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 600;
  border-radius: 2px;
  display: grid;
  align-items: center;
}

.tag:not(body) {
  margin: 8px 0px;
}

.card--image {
  height: 200px;
  border-radius: 16px;
  width: -webkit-fill-available;
  object-fit: cover;
}
.arrow-btn {
  position: absolute;
    bottom: -10px;
    right: -5px;
    height: 50px;
}
</style>
