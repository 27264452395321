var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section",class:{ 'px-6': _vm.isDesktopView }},[_c('div',{staticClass:"title-container",class:{ 'mb-6': _vm.isDesktopView }},[_c('p',{staticClass:"title-md has-text-centered"},[_vm._v("Make the most of your travel experience")])]),_c('p',{staticClass:"mb-6"},[_vm._v("Unlock a one-of-a-kind adventure in Portugal with our personalized itinerary service and expert guides. We'll handle all the details so you can kick back and enjoy the beauty and culture of Portugal.")]),_c('section',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('b-button',{staticClass:"mt-5",attrs:{"type":"is-primary","rounded":"","icon-right":"arrow-right","tag":"router-link","to":"/concierge"}},[_vm._v("Learn about our Concierge Service ")])],1),_c('b-modal',{attrs:{"scroll":"keep","full-screen":""},model:{value:(_vm.isQuoteFormActive),callback:function ($$v) {_vm.isQuoteFormActive=$$v},expression:"isQuoteFormActive"}},[_c('QuoteForm',{on:{"toggleModal":_vm.toggleModal}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"subtitle is-flex"},[_c('img',{staticClass:"mr-4 icon",attrs:{"src":require("../assets/brush.svg")}}),_vm._v(" Custom tour itinerary designed to match your preferences ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"subtitle is-flex"},[_c('img',{staticClass:"mr-4 icon",attrs:{"src":require("../assets/building.svg")}}),_vm._v(" Help with booking accommodations and overnight stays ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"subtitle is-flex"},[_c('img',{staticClass:"mr-4 icon",attrs:{"src":require("../assets/crown.svg")}}),_vm._v(" Luxurious transportation for transfers and tours ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"subtitle is-flex"},[_c('img',{staticClass:"mr-4 icon",attrs:{"src":require("../assets/messages.svg")}}),_vm._v(" Around-the-clock customer support during your trip ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"subtitle is-flex"},[_c('img',{staticClass:"mr-4 icon",attrs:{"src":require("../assets/calendar-remove.svg")}}),_vm._v(" Flexible cancellation policy for your peace of mind ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"subtitle is-flex"},[_c('img',{staticClass:"mr-4 icon",attrs:{"src":require("../assets/user-octagon.svg")}}),_vm._v(" Expert guides with in-depth knowledge of the country and a passion for sharing it with visitors ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"subtitle is-flex"},[_c('img',{staticClass:"mr-4 icon",attrs:{"src":require("../assets/task-square.svg")}}),_vm._v(" Attention to detail for a smooth and unforgettable vacation ")])
}]

export { render, staticRenderFns }