export const DestinationsData = [
    {
        id: 1,
        name: "Jerónimos Monastery",
        location: 'Lisbon, Portugal',
        hours: '10:00 AM - 6:00 PM',
        hoursExtra: "Closed on Mondays and public holidays. For updated information visit",
        fee: '€10 (Adults), Free (Children under 12)',
        website: 'www.mosteirojeronimos.pt',
        description: "This stunning monastery is a masterpiece of Manueline architecture. Built in the early 16th century, it's a symbol of Portugal's Age of Discovery and a UNESCO World Heritage site. The intricate carvings and grandeur make it a must-see.",
        lastAdmission: "5:30 PM",
        photos: [
            'https://media1.thrillophilia.com/filestore/hu62osztgz8tryxx9iemlnw0cozr_43999179605_bba1e09faf_o.jpg?w=1080&dpr=2',
        'https://media1.thrillophilia.com/filestore/xzuu2i5oevfu26x7luntpmklfej1_qr2xp5t60lgd4udfwtjgblzlmwih_shutterstock_412710898.png?w=1000&dpr=1.5',
        'https://media1.thrillophilia.com/filestore/n071jviehwgpuz2hcrfqa5ohj7fa_os0lb84tf2k2itrvcze313qx4ny9_shutterstock_124223569.png?w=1080&dpr=1.5'
        ],
        activities: ["FyBrCr0owDZFSWu7mag7"],
        tickets: ["tILBrMVXOzLveIM403Je"],
    },
    {
        id: 2,
        name: "Belém Tower",
        location: "Lisbon, Portugal",
        hours: "10:00 AM - 6:30 PM (Closed on Mondays)",
        fee: "€6 (Adults), Free (Children under 12)",
        website: "www.torrebelem.pt",
        description: "A picturesque fortress sitting at the mouth of the Tagus River, Belém Tower is a symbol of Portugal's maritime history. Climb to the top for breathtaking views of the river and the city.",
        lastAdmission: "5:00 PM",
        photos: [],
        activities: ["FyBrCr0owDZFSWu7mag7"]
    },
    {
        id: 3,
        name: "Sintra National Palace",
        location: "Sintra, Portugal",
        hours: "9:30 AM - 7:00 PM",
        fee:  "€10 (Adults), €8.50 (Seniors and Youth), Free (Children under 6)",
        website: " www.parquesdesintra.pt",
        description: "The palace is known for its iconic twin chimneys and lavishly decorated rooms. It's a glimpse into the life of Portuguese royalty, set against the fairytale backdrop of Sintra.",
        lastAdmission: "6:30 PM",
        photos: [],
        activities: []
    },
    {
        id: 4,
        name: "Pena Palace",
        location: "Sintra, Portugal",
        hours: "9:30 AM - 7:00 PM",
        fee: "€14 (Adults), €12.50 (Seniors/Youth), Free (Children under 6)",
        website: "www.parquesdesintra.pt",
        description: "Perched high on a hill, this colorful palace blends Gothic, Moorish, and Renaissance styles. It's a real-life fairytale castle with stunning panoramic views of the surrounding countryside.",
        lastAdmission: "6:00 PM",
        photos: [],
        activities: ["D1YH6p0pVThgornEsYhe"]
    },
    {
        id: 5,
        name: "Quinta da Regaleira",
        location: "Sintra, Portugal",
        hours: "9:30 AM - 8:00 PM",
        fee: "€11 (Adults), €6 (Youth/Seniors), Free (Children under 5)",
        website: "www.regaleira.pt",
        description: "This mystical estate is famous for its gardens, secret tunnels, and the Initiation Well. It's like stepping into a fantasy world, with Gothic architecture and hidden symbols at every turn.",
        lastAdmission: "7:00 PM",
        photos: [],
        activities: ["D1YH6p0pVThgornEsYhe"]
    },
    {
        id: 6,
        name: "São Jorge Castle",
        location: "Lisbon, Portugal",
        hours: "9:00 AM - 9:00 PM",
        fee: "€10 (Adults), €5 (Seniors/Students), Free (Children under 10)",
        website: "www.castelodesaojorge.pt",
        description: "Overlooking Lisbon from its highest hill, this Moorish castle offers stunning views of the city and the Tagus River. Explore the ancient walls and gardens, and imagine life in medieval Portugal.",
        lastAdmission: "8:30 PM",
        photos: [],
        activities: ["63mFURw6KKq6ZFTmGtMQ"]
    },
    {
        id: 7,
        name: "Alfama District",
        location: "Lisbon, Portugal",
        hours: "Always Open",
        fee: "Free",
        website: "",
        description: "The oldest district in Lisbon, Alfama is a maze of narrow streets, traditional Fado music, and historic charm. Wander through the alleys, discover hidden viewpoints, and soak in the authentic Portuguese atmosphere.",
        lastAdmission: "",
        photos: [],
        activities: ["FyBrCr0owDZFSWu7mag7", "63mFURw6KKq6ZFTmGtMQ"]
    },
    {
        id: 8,
        name: "Óbidos Castle",
        location: "Óbidos, Portugal",
        hours: "9:00 AM - 7:00 PM",
        fee: "Free (Castle grounds), Paid for certain events/exhibits",
        website: "www.obidos.pt",
        description: "A medieval fortress overlooking a charming walled town. Wander through cobblestone streets, discover historic churches, and enjoy the view from the castle walls. Don't miss the famous cherry liqueur, Ginjinha.",
        lastAdmission: "",
        photos: [],
        activities:  ["GqLu48OlVVI3sH6BwGZm"]
    },
    {
        id: 9,
        name: "Évora Roman Temple",
        location: "Évora, Portugal",
        hours: "Always Open",
        fee: "Free",
        website: "",
        description: "The ruins of this ancient Roman temple stand as a reminder of Évora's rich history. Surrounded by other historical buildings, it's a striking contrast of the old and the older.",
        lastAdmission: "",
        photos: [],
        activities: ["SfuJZtcgdYvaU2MgTbwj"]
    },
    {
        id: 10,
        name: "Fátima Sanctuary",
        location: "Fátima, Portugal",
        hours: "7:30 AM - 9:00 PM",
        fee: "Free",
        website: "www.fatima.pt",
        description: "A major pilgrimage site, the Sanctuary of Fátima is a place of deep spirituality and peace. Visit the basilica and chapel where the Virgin Mary is said to have appeared to three shepherd children.",
        lastAdmission: "",
        photos: [],
        activities: ["GqLu48OlVVI3sH6BwGZm"]
    },
    {
        id: 11,
        location: "",
        hours: "",
        fee: "",
        website: "",
        description: "",
        lastAdmission: "",
        photos: [],
        activities: []
    },
    {
        id: 12,
        location: "",
        hours: "",
        fee: "",
        website: "",
        description: "",
        lastAdmission: "",
        photos: [],
        activities: []
    },
    {
        id: 13,
        location: "",
        hours: "",
        fee: "",
        website: "",
        description: "",
        lastAdmission: "",
        photos: [],
        activities: []
    },
    {
        id: 14,
        location: "",
        hours: "",
        fee: "",
        website: "",
        description: "",
        lastAdmission: "",
        photos: [],
        activities: []
    },
    {
        id: 15,
        location: "",
        hours: "",
        fee: "",
        website: "",
        description: "",
        lastAdmission: "",
        photos: [],
        activities: []
    },

]