<template>
    <section>
        <!-- <div class="mb-4">
            <div class="is-flex is-align-items-center mb-5">
                <img class="icon mr-2" src="../../assets/globe.png"/>
                <p class="section--title">Our destinations</p>
            </div>
        
            <div class="is-flex mt-4">
                <div class="city--container mr-3" :class="{ 'selected': selectedCity == 'Lisbon' }"
                    @click="selectedCity = 'Lisbon'">
                    <img class="city--img"
                        src="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/attraction%2Flisboa%201%20(3)%20(1).png?alt=media&token=caafce2c-5940-4c88-bc6a-7bc3727d99ec" />
                    <div>
                        <p>Lisbon</p>
                        <div class="country--container">
                            <img class="icon mr-1" src="../../assets/Location.svg" /> Portugal
                        </div>
                    </div>

                </div>
                <div class="city--container" :class="{ 'selected': selectedCity == 'Sintra' }"
                    @click="selectedCity = 'Sintra'">
                    <img class="city--img"
                        src="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/attraction%2Fimage%20204.png?alt=media&token=1a02460d-4bd6-4a52-a5d9-bf08fb677431" />

                    <div>
                        <p>Sintra</p>
                        <div class="country--container">
                            <img class="icon mr-1" src="../../assets/Location.svg" /> Portugal
                        </div>
                    </div>
                </div>


            </div>

        </div> -->
        <component :is="selectedCityComponent" :ordered-activities="orderedActivities" :is-loading="isLoading"
            :tickets="FilteredTickets"> </component>
    </section>
</template>

<script>
import Sintra from '../../CitiesHomePage.vue/Sintra.vue';
import Lisbon from '@/CitiesHomePage.vue/Lisbon'
import { mapActions, mapState } from "vuex";
import { HelpersMixin } from '../../mixins';
import { ActivitiesActions, ActivitiesStates, TicketsActions, TicketsStates, } from "../../store/Storetypes";
import { ActivityCategories as Categories } from "../../enums/ActivityCategories";
export default {
    mixins: [HelpersMixin],
    data() {
        return {
            isLoading: true,
            Tours: [],
            FilteredTickets: [],
            orderedActivities: {},
            Categories,
            selectedCity: 'Lisbon',
            cities: ['Lisbon', 'Sintra'],
            selectedCityComponent: Lisbon,
            currentTab: 0,
            Sintra,
            Lisbon
        }
    },
    computed: {
        ...mapState({
            activities: state => state.activities[ActivitiesStates.ACTIVITIES],
            tickets: state => state.tickets[TicketsStates.TICKETS]
        }),
    },
    watch: {
        selectedCity(city) {
            this.filterActivitiesByLocation()
            this.filterTicketsByLocation()
            if (city == 'Sintra') {
                this.selectedCityComponent = Sintra
                return
            }
            this.selectedCityComponent = Lisbon

        },
        activities: {
            handler: function () {
                this.filterActivitiesByLocation()
            },
            deep: true
        }
    },
    created() {
        this.getActivities()
        this.getTickets()
        // this.filterTicketsByLocation()
    },
    methods: {
        ...mapActions({
            getActivities: ActivitiesActions.GET_ACTIVITIES,
            getTickets: TicketsActions.GET_TICKETS,
        }),
        findMinDate(eventDate) {
            let dayBeforeTimestamp = eventDate - 86400000;
            let dayBeforeDate = new Date(dayBeforeTimestamp);
            let today = new Date();
            return today < dayBeforeDate
        },
        filterTicketsByLocation() {
            this.FilteredTickets = []
            this.isLoading = true;
            if (this.selectedCity === 'Sintra') {
                this.FilteredTickets = this.tickets.filter((ticket) => ticket.city === 'Sintra, Portugal')
            } else {
                this.FilteredTickets = this.tickets
            }
            this.isLoading = false;
        },
        filterActivitiesByLocation() {
            this.orderedActivities = {}
            this.isLoading = true;
            if (this.selectedCity === 'Sintra') {
                this.Tours = this.activities.filter((activity) => activity.city === 'Sintra, Portugal')
            } else {
                this.Tours = this.activities
            }
            this.Tours.map((tour) => {
                Categories.forEach((category) => {
                    if (tour.categories.includes(category.index))
                        this.orderedActivities[category.name] = !this.orderedActivities[category.name]?.length ?
                            [tour] :
                            [
                                ...this.orderedActivities[category.name],
                                tour]
                })
            });
            this.filterTicketsByLocation()
        }
    }
}
</script>

<style lang="scss" scoped>
.country--container {
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin-right: 0.5rem;

    img {
        height: 1rem;
        width: 1rem;
    }
}

.city--container {
    display: flex;
    background-color: #f0f8ffa1;
    border-radius: 13px;
    padding: 1px 10px;
    align-items: center;
    height: 4.5rem;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    p {
        font-weight: 700;
        margin-right: .5rem;
        font-size: 1.2rem;
    }

    &.selected {
        background-color: #ffe08a;
    }
}

.city--img {
    height: 3rem;
    border-radius: 44px;
    width: 3rem;
    margin-right: 0.5rem;
    -o-object-fit: fill;
    object-fit: fill;
}

.section--title {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 32px;
}

::v-deep {

    .dropdown-item {
        font-size: 1rem;
    }

    .dropdown {
        margin-top: -8px;

        .button.is-ghost {
            span:not(.icon) {
                color: #F06543;
                font-family: "Poppins", sans-serif !important;
                border-bottom: 4px dashed #F06543;
                padding: 0 12px;
                font-size: 1.4rem;
            }
        }
    }

    .button.is-ghost:hover,
    .button.is-ghost.is-hovered {
        text-decoration: none;
    }
}
</style>