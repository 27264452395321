<template>
    <section>
        <div class="articles--container">
        <div v-for="article in articles" :key="article.name" class="article">
            <router-link :to="{ name: article.href }" class="article-card">
               <img class="article--img is-5 mb-2" :src="article.img" />  
               
                  <!--   <div v-if="article.badge" class="badge is-flex is-align-items-center mb-4">
                        <img v-if="article.badge.icon" :src="article.badge.icon" class="mr-2 icon" />
                        {{ article.badge.text }}
                    </div> -->
                    <div class="article--details mb-4">
                         <p class="article--tile mb-3">
                        {{ article.name }}
                    </p>

                    <span class="article--date"> {{ article.date }}</span>

                    <!-- <b-button icon-right="arrow-right" type="is-ghost"><b>Read article</b></b-button>
               -->
                    </div>

                   
            </router-link>
        </div>
    </div>
    </section>
</template>

<script>
export default {
    props: ['articles'],
}
</script>

<style lang="scss" scoped>
.article--date{
    color: #657687;
}
.article--details{
    padding:0 1.2rem;
}
.article{
  background-color: rgb(255 255 255);
  border-radius: 20px;
  border: 2px solid #edebf2;
}

.articles--container{
    -webkit-overflow-scrolling: touch;
  display: flex;
  gap: 1.25rem;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin: 0 -20px;
  padding: 0 20px;
  .article {
    flex: 0 0 auto;
}

    &::-webkit-scrollbar {
      display: none;
    }
}

.article-card {
    width: 78vw;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media only screen and (min-width: 40em) {
    .article-card {
      width: 54vw;
    }
  }
  
  /* Medium devices such as tablets (768px and up) */
  @media only screen and (min-width: 48em) {
    .article-card {
      width: 40vw;
    }
  }
  
  /* Large devices such as laptops (1024px and up) */
  @media only screen and (min-width: 64em) {
    .article-card {
      width: 31vw;
    }
  }
  
  /* Largest devices such as desktops (1280px and up) */
  @media only screen and (min-width: 80em) {
    .article-card {
      width: 21vw;
    }
  }
  
  @media only screen and (min-width: 1700px) {
    .article-card {
      width: 18vw;
    }
  }
  
  
  @media only screen and (min-width: 2100px) {
    .article-card {
      width: 16vw;
    }
  }
  
  
  @media only screen and (min-width: 2350px) {
    .article-card {
      width: 14vw;
    }
  }


.columns {
    margin: 0;
}

.column {
    padding: 0
}

.button.is-info.is-light {
    background-color: #f4eeab;
    font-weight: 600;
}

.icon {
    height: 1rem;
    width: 1rem;
}

.article-card {
&:not(:first-of-type){
    margin-top: 1.5rem;
}
    .badge {
        background-color: #0e3d4d;
        color: white;
        width: -moz-max-content;
        width: max-content;
        padding: 4px 6px;
        border-radius: 7px;
        font-size: 0.6rem;
        font-size: 0.75rem;
        font-weight: 600;
    }

    .article--tile {
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 20px;
        max-width: 300px;
    }

    .highlight {
        background-color: #0e3d4d;
        padding: 4px 11px;
        border-radius: 4px 4px 4px 0;
        -webkit-box-decoration-break: clone;
    }

    .article--img {
        border-radius: 20px 20px 0 0;
        height: 168px;
    width: -webkit-fill-available;
    }
}</style>