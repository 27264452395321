<template>
  <section class="section" :class="{ 'px-6': isDesktopView }">
    <div class="title-container" :class="{ 'mb-6': isDesktopView }">
      <p class="title-md  has-text-centered">Make the most of your travel experience</p>
    </div>

    <p class="mb-6">Unlock a one-of-a-kind adventure in Portugal with our personalized itinerary service and expert guides. We'll handle all the details so you can kick back and enjoy the beauty and culture of Portugal.</p>
    <!--  <section class="columns">
            <img src="../assets/guide.png" class="image column is-hidden-tablet" />
            <div class="column is-6">
                <p class="title">Private Guide Service</p>
                <p class="subtitle">
                    Experience Portugal like never before with a private guide, unlocking hidden treasures and personalized adventures tailored just for you.</p>
                <b-button type="is-primary" rounded outlined icon-right="arrow-right" class="mt-2" tag="router-link"
                    :to="{ name: 'quote' }">Request a quote
                </b-button>
                <b-button type="is-primary" rounded outlined icon-right="arrow-right" class="mt-2"
                @click="toggleModal(true)">Contact Our Experts
                </b-button>
               
            </div>
            <img src="../assets/guide.png" class="image column is-hidden-mobile" />
        </section> -->

    <section>
      
      <p class="subtitle is-flex">
        <img src="../assets/brush.svg" class="mr-4 icon" />
        Custom tour itinerary designed to match your preferences
      </p>
      <p class="subtitle is-flex">
        <img src="../assets/building.svg" class="mr-4 icon" />
        Help with booking accommodations and overnight stays
      </p>
      <p class="subtitle is-flex">
        <img src="../assets/crown.svg" class="mr-4 icon" />
        Luxurious transportation for transfers and tours
      </p>
      <p class="subtitle is-flex">
        <img src="../assets/messages.svg" class="mr-4 icon" />
        Around-the-clock customer support during your trip
      </p>
      <p class="subtitle is-flex">
        <img src="../assets/calendar-remove.svg" class="mr-4 icon" />
        Flexible cancellation policy for your peace of mind
      </p>
      <p class="subtitle is-flex">
        <img src="../assets/user-octagon.svg" class="mr-4 icon" />
        Expert guides with in-depth knowledge of the country and a passion for
        sharing it with visitors
      </p>
      <p class="subtitle is-flex">
        <img src="../assets/task-square.svg" class="mr-4 icon" />
        Attention to detail for a smooth and unforgettable vacation
      </p>

      <b-button
        type="is-primary"
        rounded
        icon-right="arrow-right"
        class="mt-5"
        tag="router-link"
        to="/concierge"
        >Learn about our Concierge Service
      </b-button>
    </section>
    <!--  <section class="columns mt-4" :class="{'mt-6':isDesktopView}">
            <img src="../assets/family.png" class="image column is-6" />
            <div class="column">
                <p class="title">Private Trips for Families</p>
                <p class="subtitle">
                    Whether you're gathering everyone for a reunion
                    in a villa-hotel, bonding with your kids in an amazing spot, or doing something completely
                    different—our
                    private family trips are all about what your family needs.</p>
                <b-button type="is-primary" rounded outlined icon-right="arrow-right" class="mt-2" tag="router-link"
                    :to="{ name: 'quote' }">Request a quote
                </b-button>
            </div>
        </section>

        <section class="columns mt-4" :class="{'mt-6':isDesktopView}">
            <img src="../assets/friends.png" class="image column is-hidden-tablet" />
            <div class="column is-6">
                <p class="title">Personalized Vacations for Groups</p>
                <p class="subtitle">
                    Explore new destinations with old friends: We're dedicated to crafting the ultimate vacation
                    experience for everyone in the group, whether you’re a group of four or forty.</p>
                <b-button type="is-primary" rounded outlined icon-right="arrow-right" class="mt-2" tag="router-link"
                    :to="{ name: 'quote' }">Request a quote
                </b-button>
            </div>
            <img src="../assets/friends.png" class="image column is-hidden-mobile" />
        </section> -->
    <b-modal v-model="isQuoteFormActive" scroll="keep" full-screen>
      <QuoteForm @toggleModal="toggleModal" />
    </b-modal>
  </section>
</template>

<script>
import { HelpersMixin } from "../mixins";
import QuoteForm from "@/components/QuoteForm";
export default {
  mixins: [HelpersMixin],
  components: {
    QuoteForm,
  },
  data() {
    return {
      isQuoteFormActive: false,
    };
  },
  methods: {
    toggleModal(isActive) {
      this.isQuoteFormActive = isActive;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  height: 2.5rem;
  width: 2.5rem;
}
.pulseBtn {
  box-shadow: 0 0 0px 0px #618efa99;
  -moz-animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
  animation: pulse 1.5s infinite;

  &:hover {
    -webkit-animation: none;
  }
}

@keyframes pulse {
  70% {
    box-shadow: 0 0 0 12px rgba(#5a99d4, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
  }
}

.section {
  background-color: #e1f4ec29;
  margin: -20px;
  padding: 3rem 20px;
}

.image {
  border-radius: 24px;
  max-height: 269px;
}

.columns {
  align-items: flex-start;
}

.title-container {
  display: grid;
  justify-items: center;
  margin-bottom: 3.75rem;
}

.title-md {
  font-size: 1.5rem;
  font-weight: 700;
  
}
.title-md:after {
    content: "";
    display: block;
    width: 40px;
    height: 2px;
    position: relative;
    background: hsl(226.18deg 90.82% 61.57%);
    bottom: -17px;
    left: 50%;
    transform: translate(-50%, 0);
}

.title {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.subtitle {
  font-size: 16px;
  margin-bottom: 1.25rem;
  color: rgba(45,59,78,0.6);
}

a {
  color: white;
}

::v-deep {
  .modal-close {
    right: 36px;
    top: 36px;
  }
  .button {
    font-size: 0.95rem;
    height: 3rem;
    border: 2px solid;
    font-weight: 700;
  }
}
</style>
